import { request2} from './request'
import qs from 'qs'

export function userLogin(op) {
  return request2({
    url: '/login',
    method: 'post',
    data: qs.stringify(op)
  })
}


//TODO deprecated 这个应该没有用  
export function gzhLogin() {
  return request2({
    url: '/nt/wx/getGzhOauth',
    method: 'get',
  })
}
export function getHomeGoods(type, page) {
  return request2({
    url: '/home/data',
    params: {
      type,page
    }
  })
}