import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const Informations = () => import('../views/informations/Informations')
const InformationsList = () => import('../views/informations/InformationsList')


const routes = [
  {
    path: '/', component: InformationsList, meta: {title: '天上闲人地上仙'}
  },
  {
    path: '/home', component: Home, meta: {title: '天上闲人地上仙'}
  },
  {
    path: '/about', component: () => import('../views/About.vue'), meta: {title: '天上闲人地上仙'}
  },
  {
    path: '/informations', component: Informations, meta: {title: '天上闲人地上仙'}
  },
  {
    path: '/informationsList', component: InformationsList, meta: {title: '天上闲人地上仙'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title=to.meta.title
  next()
})

export default router
