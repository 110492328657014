import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
app.use(store).use(router).mount('#app')


//开发模式和生产模式的配置 production
// https://cli.vuejs.org/zh/guide/mode-and-env.html#%E6%A8%A1%E5%BC%8F



import {
  Button
  , Col, Row
  , Form, Field
  , Cell, CellGroup
  , RadioGroup, Radio
  , Picker, Popup
  , Uploader, Calendar
  , DatetimePicker, Area
  , Toast, Image, Lazyload
  , NavBar, NoticeBar
  , Dialog, ActionSheet
} from 'vant'
app
  .use(Button)
  .use(Col).use(Row)
  .use(Form).use(Field)
  .use(Cell).use(CellGroup)
  .use(Radio).use(RadioGroup)
  .use(Picker).use(Popup)
  .use(Uploader).use(Calendar)
  .use(DatetimePicker).use(Area)
  .use(Toast).use(Image).use(Lazyload)
  .use(NavBar).use(NoticeBar)
  .use(Dialog).use(ActionSheet)

