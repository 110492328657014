import { createStore } from 'vuex'

export default createStore({
  state: {
    age: 222,
    token: '',
  },
  mutations: {
    setToken(state, data) {
      // 赋值
      state.token = data
    },
    addAge(state, data) {
      state.age += data
    }
  },
  actions: {
    ayAge({commit}, data) {
      setTimeout(() => {
        commit('addAge',data)
      }, 2000);
    },
  },
  getters: {
    changeAge(state) {
      console.log('no cache');
      return state.age + 5
    },
    gettersChangeToken(state) {
      return state.token
    }
  }
})
