<template>
  <div class="home">
    <div><button @click="toInfor">informations</button></div>
    <div><button @click="toInforList">toInforList</button></div>
    <div></div>
    <p/>
    <input type="text" name="username" v-model="username" />
    <input type="text" name="password" v-model="password" />
    <button type="button" @click="login()">Click Me!</button>
    
    <div>{{$store.state.age}}</div>
    <div>{{gettersChangeToken}}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import {userLogin} from 'network/home.js'
import { useStore, mapState } from "vuex";
import { computed } from "vue";

import tokenFunc from 'views/informations/token.js'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      username: '1630158426395',
      password: '123123',
    }
  },
  setup() {
    // const store = useStore();
    // const token = computed(() => store.state.token);
    // const changeAge = computed(() => store.getters.changeAge);
    // function addAge() {
    //   store.commit('addAge',50)
    // }
    // function ayAge() {
    //   store.dispatch('ayAge', 100)
    // }
    // function setToken(token) {
    //   store.commit('setToken', token)
    // }
    // // function login() {
    // //   userLogin({'username': this.username, 'password': this.password}).then(res => {
    // //     setToken(res.access_token)
    // //     console.log(store.token);
    // //   })
    // //   .catch((error) => {})
    // // }
    // return { token,setToken,changeAge, addAge, ayAge };
    let {tokenTxt, gettersChangeToken, setTokenTxt} = tokenFunc()
    return {
      tokenTxt,
      gettersChangeToken,
      setTokenTxt,
    }
  },
  methods: {
    /* 网络请求的相关方法 */
    login() {
    userLogin({'username': this.username, 'password': this.password}).then(res => {
    window.localStorage.setItem("tokenTxt",res.access_token)
      this.setTokenTxt(res.access_token)
    }).catch((error) => {})
      // axios({
      //   url: "http://localhost/api/login",
      //   method: "post",
      //   // headers: {
      //   //   Authorization: "Bearer " + items,
      //   //   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      //   // },
      //   data: qs.stringify(data),
      // })
      //   .then((response) => {
      //     console.log("res", response.data);
          
      //   })
      //   .catch((error) => {});
    },
    toInfor() {
      this.$router.push({path: '/informations',query:{ id:'2'}});
    },
    toInforList() {
      this.$router.push({path: '/informationsList',query:{ id:'2'}});
    },
  },
  computed: {
    // 方法一：--------------------------------------
    // count(){
    //   return this.$store.state.count
    // },

    //方法二：mapState-------------------------------
    //写法1：（mapState中用对象的形式获取）
    // ...mapState({
    //   count:state=>state.count
    // })
    // 写法2：mapState中用数组的形式获取）：
    // ...mapState(["token"])
  },
}
</script>
