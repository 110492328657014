import axios from 'axios'

import store from '@/store/index.js';
// store.getters.gettersChangeToken

let tokenTxt = window.localStorage.getItem('tokenTxt')

// console.log("tokenTxt req:",tokenTxt);
export function request(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API+'/api',
    timeout: 5000
  })
  // 2.axios拦截器
  instance.interceptors.request.use(config => {
    config.headers= {
      Authorization: "Bearer " + tokenTxt,
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    }
    return config
  },err => {
    // console.log(err);
  })
  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    console.log(err);
  })
  return instance(config)
  
}

export function request2(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API+'/api',
    timeout: 5000
  })
  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    console.log(err);
  })
  return instance(config)
}

export function requestUpload(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API+'/api',
    timeout: 5000,
  })
  // 2.axios拦截器
  instance.interceptors.request.use(config => {
    config.headers= {
      Authorization: "Bearer " + tokenTxt,
      "Content-Type": "multipart/form-data",
    }
    return config
  },err => {
    // console.log(err);
  })
  instance.interceptors.response.use(res => {
    return res.data
  }, err => {
    console.log(err);
  })
  return instance(config)
  
}
