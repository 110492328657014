import { useStore, mapState } from "vuex";
import { computed } from "vue";

export default function tokenFunc() {
  let store = useStore()

  
  const tokenTxt = computed(() => store.state.token);
  const gettersChangeToken = computed(() => store.getters.gettersChangeToken);

  function setTokenTxt(data22) {
    store.commit('setToken',data22)
  }
  return {
    tokenTxt,
    gettersChangeToken,
    setTokenTxt,
  }
}