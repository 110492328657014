<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
/* @import "assets/css/base.css"; */
/* @import "assets/css/aliicon/iconfont.css"; */
/* <i class="iconfont icon-xiangshang"></i> */
@import 'https://at.alicdn.com/t/font_2712051_9in1rqtip3.css?spm=a313x.7781069.1998910419.172&file=font_2712051_9in1rqtip3.css';
</style>
